import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';

// types
import { MenuProps } from 'types/menu';

// initial state
const initialState: MenuProps = {
  openItem: ['dashboard'],
  openComponent: 'buttons',
  selectedID: null,
  drawerOpen: false,
  componentDrawerOpen: true,
  menu: {},
  error: null,
  projectCreator: false,
  projectMenu: [],
  conceptBeingEdited: {id: '', name: '', logs: [], markets: []},
  activeMarket: { id: '', name: '', linkedMarkets: [], metadata: {createdAt: ''} },
  allMarkets: [],
  projectId: '',
  projectName: '',
  allowRegionsReset: false,
  menuChange: '',
  isSizeWelcome: true,
  allConcepts: [],
  availableConcepts: [],
  openedProjectName: '',
  isArticulateWelcome: false,
  openedProjectModules: [],
  activeStep: 0,
  isAiGenerate: false,
  segments: [],
  activeSubstep: 0,
  displayedResponses: [],
  modelConceptToShow: 0,
};

// ==============================|| SLICE - MENU ||============================== //

export const fetchMenu = createAsyncThunk('', async () => {
  const response = await axios.get('/api/menu/dashboard');
  return response.data;
});

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    hasError(state, action) {
      state.error = action.payload;
    },

    openProjectCreator(state, action) {
      state.projectCreator = action.payload;
    },

    setProjectMenu(state, action) {
      state.projectMenu = action.payload;
    },

    setConceptBeingEdited(state, action) {
      state.conceptBeingEdited = action.payload;
    },

    setActiveMarket(state, action) {
      state.activeMarket = action.payload;
    },

    setAllMarkets(state, action) {
      state.allMarkets = action.payload;
    },

    setProjectId(state, action) {
      state.projectId = action.payload;
    },

    setProjectName(state, action) {
      state.projectName = action.payload;
    },

    setAllowRegionsReset(state, action) {
      state.allowRegionsReset = action.payload;
    },

    setMenuChange(state, action) {
      state.menuChange = action.payload;
    },

    setIsSizeWelcome(state, action) {
      state.isSizeWelcome = action.payload;
    },

    setAllConcepts(state, action) {
      state.allConcepts = action.payload;
    },

    setAvailableConcepts(state, action) {
      state.availableConcepts = action.payload;
    },

    setOpenedProjectName(state, action) {
      state.openedProjectName = action.payload;
    },

    setIsArticulateWelcome(state, action) {
      state.isArticulateWelcome = action.payload;
    },

    setOpenedProjectModules(state, action) {
      state.openedProjectModules = action.payload;
    },

    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },

    setIsAiGenerate(state, action) {
      state.isAiGenerate = action.payload;
    },

    setSegments(state, action) {
      state.segments = action.payload;
    },

    setActiveSubstep(state, action) {
      state.activeSubstep = action.payload;
    },

    setDisplayedResponses(state, action) {
      state.displayedResponses = action.payload;
    },

    setModelConceptToShow(state, action) {
      state.modelConceptToShow = action.payload;
    }
  },

  extraReducers(builder) {
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      state.menu = action.payload.dashboard;
    });
  }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, activeID, openProjectCreator, setProjectMenu, setConceptBeingEdited, setActiveMarket, setAllMarkets, setProjectId, setProjectName, setAllowRegionsReset, setMenuChange, setIsSizeWelcome, setAllConcepts, setAvailableConcepts, setOpenedProjectName, setIsArticulateWelcome, setOpenedProjectModules, setActiveStep, setIsAiGenerate, setSegments, setActiveSubstep, setDisplayedResponses, setModelConceptToShow} = menu.actions;
